const brandNameTranslations = {
    "DeLonghi": "Делонги",
    "Kitfort": "Китфорт",
    "Philips": "Филипс",
    "Krups": "Крупс",
    "Nivona": "Нивона",
    "Melitta": "Мелитта",
    "Polaris": "Поларис",
    "Saeco": "Саеко",
    "Bosch": "Бош",
    "Jura": "Юра",
    "Smeg": "Смег",
    "Redmond": "Редмонд",
    "Siemens": "Сименс",
    "Vitek": "Витек",
    "Nespresso": "Неспрессо",
    "Xiaomi": "Сяоми",
    "Hibrew": "Хайбрю",
    "Miele": "Миле",
    "Tuvio": "Тувио",
    "Garlyn": "Гарлин",
    "Gaggia": "Гаджия",
    "Kaffit-com": "Каффит",
    "Gorenje": "Горенье",
    "Pioneer": "Пионер",
    "Rondell": "Ронделл",
    "Brayer": "Брайер",
    "Electrolux": "Электролюкс",
    "Thomson": "Томсон",
    "Aeg": "АЕГ",
    "Inhouse": "Инхаус",
    "Maunfeld": "Маунфелд",
    "Red-Solution": "Ред Солюшн",
    "KitchenAid": "КитченЭйд",
    "Beko": "Беко",
    "Wacaco": "Вакако",
    "Centek": "Сентек",
    "Kuppersbusch": "Купперсбуш",
    "Dr.Coffee": "Доктор Кофе",
    "Lelit": "Лелит",
    "Vista": "Виста",
    "Oursson": "Оурссон",
    "Ariete": "Ариете",
    "Neff": "Нефф",
    "Hyundai": "Хендай",
    "BQ": "Би Кью", 
    "KaringBee": "Карингби",
    "Nescafe Dolce Gusto": "Нескафе Дольче Густо",
    "Caso": "Касо",
    "Supra": "Супра",
    "Ningbo Rooma Electric": "Нингбо Рума Электрик",
    "Grundig": "Грюндиг",
    "Caffitaly": "Каффитали",
    "Zelmer": "Зелмер",
    "Russell Hobbs": "Рассел Хоббс",
    "JVC": "ДжейВиСи", 
    "La Pavoni": "Ла Павони",
    "Nuova Simonelli": "Нуова Симонелли",
    "Kyvol": "Кивол",
    "Teka": "Тека",
    "GFgril": "ДжиЭфГрил", 
    "Sate": "Сате",
    "Viatto": "Виатто",
    "Normann": "Норманн",
    "DEXP": "Дексп",
    "Kuppersberg": "Купперсберг",
    "Arcelik": "Арчелик",
    "Asko": "Аско",
    "First Austria": "Фирст Аустрия",
    "Home&Work": "Хоум энд Ворк",
    "Franke": "Франке",
    "JONR": "ЙОНР",
    "Futula": "Футула",
    "Sakura": "Сакура",
    "Zwilling": "Цвиллинг",
    "Gaggenau": "Гаггенау",
    "DSP": "ДСПи",
    "RAF": "РАФ",
    "Proxima": "Проксима",
    "Sanremo": "Санремо",
    "SciShare": "СкиШейр",
    "Oulemei": "Оулемей",
    "Rombica": "Ромбика",
    "De Dietrich": "Де Дитриш",
    "Arzum Okka": "Арзум Окка",
    "La Marzocco": "Ла Марзокко",
    "Delta": "Дельта",
    "Sencor": "Сенкор",
    "Gasian": "Гасиан",
    "Breville": "Бревиль",
    "Bravilor Bonamat": "Бразилор Бонамат",
    "Sage": "Сейдж",
    "Clatronic": "Клатроник",
    "Reoka": "Реока",
    "Whirlpool": "Вирпул",
    "FrancisFrancis!": "ФрэнсисФрэнсис!",
    "Leran": "Леран",
    "La Cimbali": "Ла Чимбали",
    "Graude": "Грауде",
    "Bugatti": "Бугатти",
    "Ufesa": "Уфеса",
    "Donlim": "Донлим",
    "Conti": "Конти",
    "Illy": "Илли",
    "Gelberk": "Гельберк",
    "Manya": "Маня",
    "Hosta Home": "Хоста Хоум",
    "Rise": "Райз",
    "Deerma": "Дирма",
    "Kimbo": "Кимбо",
    "Airhot": "Эйрхот",
    "Graef": "Греф",
    "Korting": "Кортинг",
    "Geniard": "Джениард",
    "EKSI": "Экси",
    "Weissgauff": "Вайсгауфф",
    "Bertazzoni": "Бертаццони",
    "Black+Decker": "Блэк энд Декер",
    "Zepter": "Цептер",
    "Necta": "Некта",
    "Aroma": "Арома",
    "Fulgor Milano": "Фулгор Милано",
    "KEF": "Кеф",
    "Solac": "Солак",
    "Alvatto": "Альватто",
    "Cafe Silvestre": "Кафе Сильвестр",
    "Felfri": "Фельфри",
    "Jacky’s": "Джекис",
    "Travola": "Травола",
    "Kuchenland": "Кухенленд",
    "SWAN": "СВАН",
    "Senix": "Сеникс",
    "VARD": "Вард",
    "ADRC Company": "АДРСи Компани",
    "Gemilai": "Джемилай",
    "Proliss": "Пролисс",
    "Catler": "Катлер",
    "Carrera": "Каррера",
    "Manifesta": "Манифеста",
    "Saint Coffee": "Сейнт Кофе",
    "Agave": "Агаве",
    "Blaupunkt": "Блаупункт",
    "BlitzWolf": "БлицВульф",
    "Caffiano": "Каффиано",
    "Dauscher": "Даушер",
    "MIA": "Миа",
    "OubaOloon": "ОубаОлун",
    "Epeios": "Эпейос",
    "Василиса": "Василиса",
    "Аксинья": "Аксинья",
    "MasterKofe": "МастерКофе",
    "WMF": "ВМФ",
    "BORK": "Борк",
    "ENDEVER": "Эндевер",
    "EUPA": "ЕУПА",
    "Faber": "Фабер",
    "FAEMA": "ФАЕМА",
    "Gastroback": "Гастробек",
    "Gemlux": "Гемлюкс",
    "Indesit": "Индезит",
    "Kaffit": "Каффит",
    "Kambrook": "Кэмбрук",
    "Kenwood": "Кенвуд",
    "La Spaziale": "Ла Спациале",
    "Laretti": "Ларетти",
    "Panasonic": "Панасоник",
    "Princess": "Принцесс",
    "Rancilio": "Ранчилио",
    "Rommelsbacher": "Роммельсбахер",
    "Rowenta": "Ровента",
    "Spinel": "Спинел",
    "Thermador": "Термадор",
    "WOLF": "Вулф",
    "WPM": "ВПМ",
    "V-ZUG": "В-Цуг"
  };

export default brandNameTranslations; 