import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/CoffeeMachineIssuesDropdown.css';

const CoffeeMachineIssuesDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const coffeeMachineIssues = [
    {
      title: "Не включается",
      slug: "ne-vklyuchaetsya",
      price: "500 руб",
      description: "Самой распространенной причиной того, что кофемашина не включается, является неисправный термоблок. Также стоит проверить сетевой кабель и блок питания перед вызовом мастера."
    },
    {
      title: "Не качает воду",
      slug: "ne-kachaet-vodu",
      price: "400 руб",
      description: "Проблема с подачей воды может быть вызвана забитым фильтром или насосом. Проверьте систему подачи воды и замените фильтры."
    },
    {
      title: "Не работает помол",
      slug: "ne-rabotaet-pomol",
      price: "600 руб",
      description: "Если помол кофе не происходит, возможно, проблема в блокировке жерновов или неисправности двигателя помола. Требуется диагностика и ремонт или замена компонента."
    },
    {
      title: "Не наливает кофе",
      slug: "ne-nalivet-kofe",
      price: "600 руб",
      description: "Отсутствие налива кофе может указывать на засор в системе подачи или неисправность блока клапанов. Рекомендуется профессиональная очистка системы."
    },
    {
      title: "Не подает пену",
      slug: "ne-podaet-penu",
      price: "800 руб",
      description: "Причиной отсутствия пены может быть проблема с капучинатором или засор в трубке подачи молока. Очистите капучинатор и проверьте систему подачи молока."
    },
    {
      title: "Не работают кнопки",
      slug: "ne-rabotayut-knopki",
      price: "1000 руб",
      description: "Неисправность кнопок может быть вызвана износом или повреждением сенсорной панели. Требуется замена кнопок или сенсоров."
    },
    {
      title: "Протекает",
      slug: "protekaet",
      price: "700 руб",
      description: "Протечка может быть вызвана износом уплотнителей или трещинами в резервуаре. Проверьте уплотнители и замените поврежденные детали."
    },
    {
      title: "Не нагревает воду",
      slug: "ne-nagrevaet-vodu",
      price: "1000 руб",
      description: "Причиной того, что вода не нагревается, может быть неисправный нагревательный элемент или термостат. Необходима проверка и, при необходимости, замена этих компонентов."
    },
    {
      title: "Не вставляется блок",
      slug: "ne-vstavlyaetsya-blok",
      price: "900 руб",
      description: "Если блок не вставляется, возможно, проблема в механическом заедании или неисправности направляющих. Рекомендуется осмотр и смазка движущихся частей."
    },
    {
      title: "Не работает капучинатор",
      slug: "ne-rabotaet-kapuchinator",
      price: "900 руб",
      description: "Неисправность капучинатора может быть вызвана засором или поломкой механизма подачи пара. Требуется чистка или ремонт капучинатора."
    },
    {
      title: "Не работает",
      slug: "ne-rabotaet",
      price: "700 руб",
      description: "Общая неисправность может быть вызвана различными проблемами, включая проблемы с электропитанием или повреждение внутренних компонентов."
    },
    {
      title: "Не работает помпа",
      slug: "ne-rabotaet-pompa",
      price: "800 руб",
      description: "Неисправная помпа может вызвать проблемы с подачей воды. Возможно, потребуется замена помпы или проверка её подключения."
    },
    {
      title: "Не варит кофе",
      slug: "ne-varit-kofe",
      price: "800 руб",
      description: "Если кофемашина не варит кофе, проблема может быть связана с заварочным блоком или системами подачи воды и пара. Необходима диагностика."
    },
    {
      title: "Не взбивает молоко",
      slug: "ne-vzbivaet-moloko",
      price: "800 руб",
      description: "Причиной того, что кофемашина не взбивает молоко, могут быть проблемы с системой подачи пара или загрязнение форсунок. Проведите очистку и проверку оборудования."
    },
    {
      title: "Не видит воду",
      slug: "ne-vidit-vodu",
      price: "900 руб",
      description: "Если кофемашина не видит воду, возможно, сенсоры уровня воды неисправны или забиты. Проверьте и очистите сенсоры."
    },
    {
      title: "Не видит кофе",
      slug: "ne-vidit-kofe",
      price: "1000 руб",
      description: "Проблемы с распознаванием кофе могут быть связаны с датчиками уровня или с системой подачи кофе. Требуется осмотр и диагностика."
    },
    {
      title: "Не горячий кофе",
      slug: "ne-goryachiy-kofe",
      price: "1000 руб",
      description: "Если кофе не горячий, возможно, неисправен термоблок или датчик температуры. Проверьте систему нагрева и, при необходимости, замените компоненты."
    },
    {
      title: "Не греет",
      slug: "ne-greet",
      price: "800 руб",
      description: "Если кофемашина не греет, возможно, проблема в нагревательных элементах или в системе подачи энергии. Требуется диагностика и ремонт."
    },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleIssueClick = (issue) => {
    window.location.href = `/neispravnosti/${issue.slug}`;
  };

  return (
    <div className="coffee-issues-dropdown"
         onMouseEnter={toggleDropdown}
         onMouseLeave={toggleDropdown}
    >
      <div className="dropdown-button">
        Неисправности кофемашин
      </div>
      {isOpen && (
        <ul className="dropdown-content">
          {coffeeMachineIssues.map((issue) => (
            <li key={issue.slug} onClick={() => handleIssueClick(issue)}>
              {issue.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CoffeeMachineIssuesDropdown;