export const districts = [
    'Алексеевский', 'Академический', 'Алтуфьевский', 'Арбат', 'Аэропорт',
    'Бабушкинский', 'Басманный', 'Беговой', 'Бескудниковский', 'Бибирево',
    'Бирюлево Восточное', 'Бирюлево Западное', 'Богородское', 'Братеево',
    'Бутово Северное', 'Бутово Южное', 'Бутырский', 'Вешняки', 'Внуково',
    'Войковский', 'Восточный', 'Выхино-Жулебино', 'Гагаринский', 'Головинский',
    'Гольяново', 'Даниловский', 'Дегунино-Восточное', 'Дегунино-Западное',
    'Дмитровский', 'Донской', 'Дорогомилово', 'Замоскворечье', 'Зюзино',
    'Зябликово', 'Ивановское', 'Измайлово', 'Капотня', 'Коньково', 'Коптево',
    'Косино-Ухтомский', 'Котловка', 'Красносельский', 'Крылатское', 'Крюково',
    'Кузьминки', 'Кунцево', 'Куркино', 'Левобережный', 'Лефортово', 'Лианозово',
    'Ломоносовский', 'Лосиноостровский', 'Люблино', 'Марфино', 'Марьина Роща',
    'Марьино', 'Матюшкино', 'Медведково Северное', 'Медведково Южное',
    'Метрогородок', 'Мещанский', 'Митино', 'Можайский', 'Молжаниновский',
    'Москворечье-Сабурово', 'Нагатино-Садовники', 'Нагатинский Затон', 'Нагорный',
    'Некрасовка', 'Нижегородский', 'Ново-Переделкино', 'Новогиреево',
    'Новокосино', 'Обручевский', 'Орехово-Борисово Северное', 'Орехово-Борисово Южное',
    'Останкинский', 'Отрадное', 'Очаково-Матвеевское', 'Перово', 'Печатники',
    'Покровское-Стрешнево', 'Преображенское', 'Пресненский', 'Проспект Вернадского',
    'Раменки', 'Ростокино', 'Рязанский', 'Савелки', 'Савеловский', 'Свиблово',
    'Северный', 'Силино', 'Сокол', 'Соколиная Гора', 'Сокольники', 'Солнцево',
    'Старое Крюково', 'Строгино', 'Таганский', 'Тверской', 'Текстильщики',
    'Тимирязевский', 'Теплый Стан', 'Тропарево-Никулино', 'Тушино Северное',
    'Тушино Южное', 'Филевский Парк', 'Фили-Давыдково', 'Хамовники', 'Ховрино',
    'Хорошево-Мневники', 'Хорошевский', 'Царицыно', 'Черемушки', 'Чертаново Северное',
    'Чертаново Центральное', 'Чертаново Южное', 'Щукино', 'Южнопортовый', 'Якиманка',
    'Ярославский', 'Ясенево'
  ];
  
  export const cities = [
    'Алабушево', 'Андреевка', 'Апрелевка', 'Балашиха', 'Барвиха',
    'Барыбино', 'Белоозерский', 'Бронницы', 'Быково', 'Верея',
    'Вёшки', 'Видное', 'Владимир', 'Власиха', 'поселок Внуково',
    'Волоколамск', 'Воскресенск', 'Востряково', 'Высоковск', 'Голицыно',
    'Дедовск', 'Дзержинский', 'Дмитров', 'Долгопрудный', 'Домодедово',
    'Дрезна', 'Дубна', 'Егорьевск', 'Жаворонки', 'Железнодорожный',
    'Жуковский', 'Загорянский', 'Запрудня', 'Зарайск', 'Заречье',
    'Звенигород', 'Зеленоград', 'Ивантеевка', 'Икша', 'Ильинский',
    'Истра', 'Калининец', 'Кашира', 'Климоск', 'Клин',
    'Клязьма', 'Коломна', 'Коммунарка', 'Конаково', 'Королёв',
    'Котельники', 'Красково', 'Красноармейск', 'Красногорск', 'Краснозаводск',
    'Краснознаменск', 'Кратово', 'Крёкшино', 'Крюково', 'Кубинка',
    'Купавна', 'Куровское', 'Дикино-Дулёво', 'Лобня', 'Лосино-Петровский',
    'Луговая', 'Луховицы', 'Лыткарино', 'Львовский', 'Люберцы',
    'Малаховка', 'Мамонтовка', 'Михнево', 'Можайск', 'Монино',
    'Московский', 'Мытищи', 'Наро-Фоминск', 'Нахабино', 'Некрасовский',
    'Немчиновка', 'Ногинск', 'Обухово', 'Одинцово', 'Ожерелье',
    'Озёры', 'Опалиха', 'Орехово-Зуево', 'Павловская Слобода', 'Павловский Посад',
    'Пересвет', 'Петровское', 'Пироговский', 'Поварово', 'Подольск',
    'Правдинский', 'Протвино', 'Путилково', 'Пушкино', 'Пущино',
    'Развилка', 'Раменское', 'Рассказовка', 'РАстуново', 'Реутов',
    'Рошаль', 'Руза', 'Румянцево', 'Саларьево', 'Селятино',
    'Сергиев Посад', 'Серпухов', 'Солнечногорск', 'Сосенки', 'Софрино',
    'Старая Купавна', 'Столбовая', 'Ступино', 'Сходня', 'Талдом',
    'Томилино', 'Трёхгорка', 'Троицк', 'Тучково', 'Удельная',
    'Фрязино', 'Фряново', 'Химки', 'Хотьково', 'Черноголовка',
    'Чехов', 'Шатура', 'Щелково', 'Щербинка', 'Электрогорск',
    'Электросталь', 'Электроугли', 'Яхрома'
  ];
  