import React from "react";
import "../styles/MapSection.css";

const MapSection = () => {
  return (
    <section className="map-wrapper">
      <div className="map-container">
        <iframe
          className="map-frame"
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A3b060fee38b975fe09332471a97e593bdaf90b1156dd7deb3231996faff23ef0&amp;source=constructor"
          allowFullScreen
          loading="lazy"
        ></iframe>
        <div className="map-info">
          <h3>
            Звоните <span className="red-separator">|</span> Без выходных и
            праздников <span className="red-separator">|</span> с 8 до 21
          </h3>
          <div className="svg-text-container">
            <svg
              className="red-circle-svg"
              width="24"
              height="24"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="XMLID_525_">
                <path
                  id="XMLID_426_"
                  d="M25.2,27C13,27,3,17,3,4.8C3,3.8,3.8,3,4.8,3h4.5c1,0,1.8,0.8,1.8,1.8c0,1.5,0.2,3,0.7,4.4 c0.2,0.6,0,1.3-0.4,1.8l-2.1,2.8c1.7,3.2,3.8,5.3,7,7l2.8-2.1c0.4-0.4,1.1-0.6,1.8-0.4c1.4,0.5,2.9,0.7,4.4,0.7 c1,0,1.8,0.8,1.8,1.8v4.5C27,26.2,26.2,27,25.2,27z M4.8,4C4.3,4,4,4.3,4,4.8C4,16.5,13.5,26,25.2,26c0.4,0,0.8-0.3,0.8-0.8v-4.5 c0-0.4-0.3-0.8-0.8-0.8c-1.6,0-3.2-0.3-4.7-0.8c-0.3-0.1-0.6,0-0.8,0.2l-3.1,2.4c-0.2,0.1-0.4,0.1-0.5,0c-3.7-1.9-6-4.2-7.9-7.9 c-0.1-0.2-0.1-0.4,0-0.5l2.3-3.1c0.3-0.3,0.3-0.6,0.2-0.9C10.3,8,10,6.4,10,4.8C10,4.3,9.7,4,9.3,4L4.8,4L4.8,4z"
                ></path>
              </g>
            </svg>
            <a
              href="tel:+79163925911"
              style={{
                margin: "5px 0 13px",
                fontSize: "17px",
                color: "#000000",
                paddingTop: "10px",
                textDecoration: "none",
                backgroundColor: "transparent",
              }}
            >
              +7 (916) 392-59-11
            </a>
          </div>
          <div className="svg-text-container">
            <svg
              className="red-circle-svg"
              width="24"
              height="24"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                transform="scale(0.8) translate(3.75, 3.75)"
                transform-origin="0 0"
              >
                <g>
                  <g>
                    <g>
                      <path d="M15,17c-3.6,0-6.5-2.9-6.5-6.5S11.4,4,15,4s6.5,2.9,6.5,6.5S18.6,17,15,17z M15,5c-3,0-5.5,2.5-5.5,5.5S12,16,15,16 s5.5-2.5,5.5-5.5S18,5,15,5z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M15,17c-3.6,0-6.5-2.9-6.5-6.5S11.4,4,15,4s6.5,2.9,6.5,6.5S18.6,17,15,17z M15,5c-3,0-5.5,2.5-5.5,5.5S12,16,15,16 s5.5-2.5,5.5-5.5S18,5,15,5z"></path>
                    </g>
                  </g>
                  <g>
                    <path d="M15,30c-0.1,0-0.2,0-0.3-0.1c-0.4-0.4-10.2-9.2-10.2-19.4C4.5,4.7,9.2,0,15,0s10.5,4.7,10.5,10.5c0,10.2-9.8,19-10.2,19.4 C15.2,30,15.1,30,15,30z M15,1c-5.2,0-9.5,4.3-9.5,9.5c0,8.7,7.8,16.7,9.5,18.3c1.7-1.6,9.5-9.6,9.5-18.3C24.5,5.3,20.2,1,15,1z"></path>
                  </g>
                </g>
              </g>
            </svg>
            <p>3-й Нижнелихоборский пр., 3, стр. 2, Москва</p>
          </div>
          <div className="svg-text-container">
            <svg
              className="red-circle-svg"
              width="24"
              height="24"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M16,24.5h-2c-0.3,0-0.5,0.2-0.5,0.5v4c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5v-4C16.5,24.7,16.3,24.5,16,24.5z"></path>
                <path d="M29,13.5h-4c-0.3,0-0.5,0.2-0.5,0.5v2c0,0.3,0.2,0.5,0.5,0.5h4c0.3,0,0.5-0.2,0.5-0.5v-2C29.5,13.7,29.3,13.5,29,13.5z"></path>
                <path d="M5.5,16v-2c0-0.3-0.2-0.5-0.5-0.5H1c-0.3,0-0.5,0.2-0.5,0.5v2c0,0.3,0.2,0.5,0.5,0.5h4C5.3,16.5,5.5,16.3,5.5,16z"></path>
                <path d="M8.9,3.5C8.8,3.3,8.5,3.2,8.3,3.3C8,3.4,7.9,3.8,8.1,4l1,1.7C9.2,5.9,9.3,6,9.5,6c0.1,0,0.2,0,0.3-0.1 c0.2-0.1,0.3-0.4,0.2-0.7L8.9,3.5z"></path>
                <path d="M5.7,9.1L4,8.1C3.8,7.9,3.4,8,3.3,8.3C3.2,8.5,3.3,8.8,3.5,8.9l1.7,1C5.3,10,5.4,10,5.5,10c0.2,0,0.3-0.1,0.4-0.3 C6,9.5,6,9.2,5.7,9.1z"></path>
                <path d="M5.2,20.1l-1.7,1c-0.2,0.1-0.3,0.4-0.2,0.7C3.4,21.9,3.6,22,3.7,22c0.1,0,0.2,0,0.3-0.1l1.7-1C6,20.8,6,20.5,5.9,20.3 C5.8,20,5.5,19.9,5.2,20.1z"></path>
                <path d="M9.8,24.1C9.5,24,9.2,24,9.1,24.3l-1,1.7c-0.1,0.2-0.1,0.5,0.2,0.7c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.4-0.3l1-1.7 C10.1,24.5,10,24.2,9.8,24.1z"></path>
                <path d="M20.9,24.3c-0.1-0.2-0.4-0.3-0.7-0.2c-0.2,0.1-0.3,0.4-0.2,0.7l1,1.7c0.1,0.2,0.3,0.3,0.4,0.3c0.1,0,0.2,0,0.3-0.1 c0.2-0.1,0.3-0.4,0.2-0.7L20.9,24.3z"></path>
                <path d="M26.5,21.1l-1.7-1c-0.2-0.1-0.5-0.1-0.7,0.2c-0.1,0.2-0.1,0.5,0.2,0.7l1.7,1c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.4-0.3 C26.8,21.5,26.7,21.2,26.5,21.1z"></path>
                <path d="M24.5,10c0.1,0,0.2,0,0.3-0.1l1.7-1c0.2-0.1,0.3-0.4,0.2-0.7C26.6,8,26.2,7.9,26,8.1l-1.7,1C24,9.2,24,9.5,24.1,9.8 C24.2,9.9,24.4,10,24.5,10z"></path>
                <path d="M21.8,3.3c-0.2-0.1-0.5-0.1-0.7,0.2l-1,1.7c-0.1,0.2-0.1,0.5,0.2,0.7C20.3,6,20.4,6,20.5,6c0.2,0,0.3-0.1,0.4-0.3l1-1.7 C22.1,3.8,22,3.4,21.8,3.3z"></path>
                <path d="M16.4,17.1l4.3,4.3c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-4.3-4.3c0.3-0.4,0.4-0.9,0.4-1.4 c0-1-1.4-7.4-1.9-9.5H16c0.3,0,0.5-0.2,0.5-0.5V1c0-0.3-0.2-0.5-0.5-0.5h-2c-0.3,0-0.5,0.2-0.5,0.5v4c0,0.3,0.2,0.5,0.5,0.5h0.4 c-0.5,2.1-1.9,8.5-1.9,9.5c0,1.4,1.1,2.5,2.5,2.5C15.5,17.5,16,17.3,16.4,17.1z"></path>
              </g>
            </svg>
            <p>График работы: 8:00 - 21:00. Без выходных</p>
          </div>
          <div class="svg-text-container">
            <svg
              className="red-circle-svg"
              width="24"
              height="24"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                transform="scale(0.8) translate(3.75, 3.75)"
                transform-origin="0 0"
              >
                <g>
                  <g>
                    <path d="M15,30c-5.3,0-11-0.9-11-3c0-1.9,5-2.8,9.3-3c0.2,0,0.5,0.2,0.5,0.4l0.2,1.5h2.1l0.2-1.5c0-0.3,0.3-0.4,0.5-0.4 c4.3,0.2,9.3,1,9.3,3C26,29.1,20.3,30,15,30z M12.8,25.1C7.6,25.3,5,26.4,5,27c0,0.7,3.5,2,10,2s10-1.3,10-2 c0-0.6-2.6-1.7-7.8-1.9L17,26.6c0,0.3-0.2,0.4-0.5,0.4h-3c-0.3,0-0.5-0.2-0.5-0.4L12.8,25.1z"></path>
                  </g>
                  <g>
                    <path d="M16.5,27h-3c-0.3,0-0.5-0.2-0.5-0.4l-1-8.6l-0.4,0c-0.4,0-0.9-0.2-1.3-0.6s-0.5-0.9-0.4-1.4l0.5-6.3C10.7,8.1,12,7,13.5,7 h3c1.5,0,2.8,1.1,3,2.6l0.5,6.4c0.1,0.5-0.1,1-0.4,1.4c-0.3,0.4-0.8,0.6-1.3,0.6h-0.3l-1,8.6C17,26.8,16.8,27,16.5,27z M13.9,26 h2.1l1-8.6c0-0.3,0.2-0.4,0.5-0.4h0.8c0.2,0,0.4-0.1,0.6-0.3s0.2-0.4,0.2-0.6l-0.5-6.4c-0.1-1-1-1.7-2-1.7h-3c-1,0-1.9,0.8-2,1.8 L11,16c0,0.2,0,0.4,0.2,0.6c0.1,0.2,0.3,0.3,0.6,0.3l0.8,0.1c0.2,0,0.4,0.2,0.4,0.4L13.9,26z"></path>
                  </g>
                  <g>
                    <path d="M15,6c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S16.7,6,15,6z M15,1c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S16.1,1,15,1z"></path>
                  </g>
                </g>
              </g>
            </svg>
            <p>Зона выезда мастеров: Москва + 20км от МКАД</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MapSection;
